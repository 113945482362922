"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractDeliverables = exports.getProjectDeliverables = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getProjectDeliverables(projectId, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.paygOnly, paygOnly = _c === void 0 ? false : _c;
    var url = "/api/project/".concat(projectId, "/deliverables");
    if (paygOnly) {
        url = "".concat(url, "?paygOnly=true");
    }
    return api_1.default.get(url).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getProjectDeliverables = getProjectDeliverables;
function getContractDeliverables(contractId, invoiceId) {
    var invoiceIdParam = invoiceId ? "?invoiceId=".concat(invoiceId) : '';
    return api_1.default.get("/api/contract/".concat(contractId, "/deliverables").concat(invoiceIdParam)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getContractDeliverables = getContractDeliverables;
var api = {
    getByProject: getProjectDeliverables,
    getByContract: getContractDeliverables,
};
exports.default = api;
