import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'
import queryString from 'query-string'

import {
  Login,
  ForgotPassword,
  ForgotPasswordSuccess,
  ResetPassword,
  ResetPasswordSuccess,
  QuickLogin,
} from '@root/Auth'
import Spinner from '@components/Spinner'
import Header from '@components/Header'
import SubHeader from '@components/SubHeader'
import Modal from '@components/Modal'
import Tabs from '@components/Tabs'
import { GrowlWrapper } from '@atheneum/ui-components'
import ClientAccount from '@root/ClientAccount'
import ActiveProjects from '@root/ClientProjects'
import ComplianceAuditListing from '@root/ComplianceAudit'
import CreateProjectForm from '@root/CreateProjectForm'
import SingleEpl from '@root/ComplianceAudit/Views'
import { NotFound } from '@components/NoContent'

import './index.sass'

class App extends Component {
  static propTypes = {
    userStore: PropTypes.object,
  }

  constructor(props) {
    super(props)

    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    })
  }

  render() {
    const { user, loading, isQuickLoginInProgress } = this.props.userStore
    if (isQuickLoginInProgress) return <Spinner />
    if (!user.has('id')) {
      return (
        <Switch>
          <Route exact path="/auth/quick-login" component={QuickLogin} />
          <Route exact path="/auth/forgot-password" component={ForgotPassword} />
          <Route exact path="/auth/forgot-password-success" component={ForgotPasswordSuccess} />
          <Route exact path="/auth/reset-password/:token" component={ResetPassword} />
          <Route exact path="/auth/platform-invitation/:token" render={() => <ResetPassword setPassword />} />
          <Route exact path="/auth/reset-password-success" component={ResetPasswordSuccess} />
          <Route exact path="/auth/login" component={Login} />
          {!loading && <Redirect to="/auth/login" />}
        </Switch>
      )
    } else {
      return <LoggedInAppWithStaticData />
    }
  }
}

class LoggedInApp extends Component {
  static propTypes = {
    staticDataStore: PropTypes.object,
    activeProjectsStore: PropTypes.object,
    projectStore: PropTypes.object,
    modalStore: PropTypes.object,
  }

  componentDidMount() {
    this.loadStores()
  }

  loadStores = async () => {
    const { staticDataStore, activeProjectsStore, userStore } = this.props
    await staticDataStore.init()
    const client = userStore.user.get('client')
    if (client.knowledgeManager) {
      activeProjectsStore.loadActiveProjects(client)
      userStore.getUser()
    }
  }

  render() {
    const { staticDataStore, activeProjectsStore, userStore } = this.props

    const client = userStore.user.get('client')
    const { loading, initialized } = staticDataStore
    let isLoading = loading || !initialized
    if (client.knowledgeManager) {
      isLoading = isLoading || !activeProjectsStore.initialized
    }

    if (isLoading) {
      return <Spinner />
    }

    const { location } = queryString.parse(this.props.location.search)

    return (
      <div className="app">
        <GrowlWrapper />
        <Modal />
        <Header />
        <SubHeader />
        <Tabs />
        <div className="view-container">
          <Switch>
            <Redirect path="/auth/quick-login" to={location || '/'} />
            <Redirect exact path="/" to={client.knowledgeManager ? '/dashboard' : '/audit'} />
            <Redirect exact path="/auth/login" to={client.knowledgeManager ? '/dashboard' : '/audit'} />
            <Route exact path="/dashboard" component={ActiveProjects} />
            <Route exact path="/my-account" component={ClientAccount} />
            <Route exact path="/audit" component={ComplianceAuditListing} />
            <Route exact path="/audit/:id" component={SingleEpl} />
            <Route exact path="/project" component={CreateProjectForm} />
            <Route exact path="/project/:id" component={CreateProjectForm} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    )
  }
}

const LoggedInAppWithStaticData = withRouter(
  inject('staticDataStore', 'userStore', 'modalStore', 'activeProjectsStore', 'projectStore')(observer(LoggedInApp)),
)

export default withRouter(inject('userStore')(observer(App)))
