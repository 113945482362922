import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import ACL from '../ACL'

const Tabs = ({ userStore }) => {
  const client = userStore.user.get('client')
  return (
    <div className="tabs__container">
      <div className="container">
        <nav className="tabs__scrollable-container">
          <div className="tabs">
            <ACL isAllowed={client.knowledgeManager}>
              <NavLink exact to="/dashboard" className="tab" activeClassName="tab--active">
                <li className="tab__link">Projects</li>
              </NavLink>
            </ACL>

            <ACL isAllowed={client.complianceAuditor}>
              <NavLink exact to="/audit" className="tab" activeClassName="tab--active">
                <li className="tab__link">Audit</li>
              </NavLink>
            </ACL>

            <NavLink exact to="/my-account" className="tab" activeClassName="tab--active">
              <li className="tab__link">Account</li>
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
  )
}

// note: use withRouter helper to fix mobx change detection, info is here: https://github.com/mobxjs/mobx-react/issues/210
export default withRouter(inject('userStore')(observer(Tabs)))
