"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInvoiceDelayReason = exports.exportAllInvoices = exports.getAllInvoices = exports.getLinkedInvoice = exports.setCreditNoteToApprovedStatus = exports.createCreditNote = exports.deleteInvoicePayment = exports.updatePayments = exports.publishInvoiceSent = exports.sendInvoice = exports.getSendInvoiceData = exports.setCreatedStatus = exports.disapproveInvoice = exports.approveInvoice = exports.requestInvoiceApproval = exports.updateInvoiceItems = exports.getContractInvoices = exports.getProjectInvoices = exports.deleteFeeFromInvoice = exports.deleteInvoiceItem = exports.deleteInvoice = exports.updateInvoice = exports.createInvoice = exports.getInvoice = void 0;
var tslib_1 = require("tslib");
var api_1 = tslib_1.__importStar(require("./api"));
function getInvoice(id) {
    return api_1.default.get("/api/invoice/".concat(id)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getInvoice = getInvoice;
function createInvoice(invoiceData) {
    return api_1.default
        .post('/api/invoice', tslib_1.__assign({}, invoiceData))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.createInvoice = createInvoice;
function updateInvoice(invoiceData) {
    return api_1.default
        .put('/api/invoice/' + invoiceData.id, tslib_1.__assign({}, invoiceData))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateInvoice = updateInvoice;
function deleteInvoice(invoiceId) {
    return api_1.default.delete("/api/invoice/".concat(invoiceId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteInvoice = deleteInvoice;
function deleteInvoiceItem(invoiceItemId) {
    return api_1.default.delete("/api/invoice-item/".concat(invoiceItemId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteInvoiceItem = deleteInvoiceItem;
function deleteFeeFromInvoice(invoiceId, feeId) {
    return api_1.default.delete("/api/invoice/".concat(invoiceId, "/fees/").concat(feeId)).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.deleteFeeFromInvoice = deleteFeeFromInvoice;
function getProjectInvoices(projectId) {
    return api_1.default
        .get('/api/project/' + projectId + '/invoices?includeFees=true&includeInvoiceItems=true')
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getProjectInvoices = getProjectInvoices;
function getContractInvoices(contractId) {
    return api_1.default
        .get('/api/contract/' + contractId + '/invoices?includeFees=true&includeInvoiceItems=true')
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.getContractInvoices = getContractInvoices;
function updateInvoiceItems(invoiceId, invoiceItems) {
    return api_1.default
        .put("/api/invoice/".concat(invoiceId, "/invoice-items"), {
        invoiceItems: invoiceItems,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateInvoiceItems = updateInvoiceItems;
function requestInvoiceApproval(invoiceId) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/request-approval")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.requestInvoiceApproval = requestInvoiceApproval;
function approveInvoice(invoiceId) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/approve")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.approveInvoice = approveInvoice;
function disapproveInvoice(invoiceId, disapprovedReason) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/disapprove"), { disapprovedReason: disapprovedReason }).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.disapproveInvoice = disapproveInvoice;
function setCreatedStatus(invoiceId, data) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/created-status"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.setCreatedStatus = setCreatedStatus;
function getSendInvoiceData(invoiceId) {
    return api_1.default.get("/api/invoice/".concat(invoiceId, "/send-invoice")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getSendInvoiceData = getSendInvoiceData;
function sendInvoice(invoiceId, sendingData) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/send-invoice"), sendingData).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.sendInvoice = sendInvoice;
function publishInvoiceSent(invoiceId) {
    return api_1.default.get("/api/invoice/".concat(invoiceId, "/publish-invoice-sent")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.publishInvoiceSent = publishInvoiceSent;
function updatePayments(invoiceId, invoicePayments) {
    return api_1.default
        .put("/api/invoice/".concat(invoiceId, "/invoice-payments"), {
        invoicePayments: invoicePayments,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updatePayments = updatePayments;
function deleteInvoicePayment(invoiceId, invoicePaymentId) {
    return api_1.default
        .delete("/api/invoice/".concat(invoiceId, "/invoice-payments/").concat(invoicePaymentId))
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.deleteInvoicePayment = deleteInvoicePayment;
function createCreditNote(invoiceId, data) {
    return api_1.default.post("/api/invoice/".concat(invoiceId, "/credit-note"), data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.createCreditNote = createCreditNote;
function setCreditNoteToApprovedStatus(invoiceId, invoiceDates) {
    return api_1.default.put("/api/invoice/".concat(invoiceId, "/approved-credit-note"), invoiceDates).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.setCreditNoteToApprovedStatus = setCreditNoteToApprovedStatus;
function getLinkedInvoice(invoiceId) {
    return api_1.default.get("/api/invoice/".concat(invoiceId, "/linked-invoice")).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getLinkedInvoice = getLinkedInvoice;
function getAllInvoices() {
    return api_1.default.get("/api/invoice/invoice-listing").then(api_1.extractData).catch(api_1.errorHandler);
}
exports.getAllInvoices = getAllInvoices;
function exportAllInvoices(data) {
    return api_1.default.post("/api/invoice/invoice-export", data).then(api_1.extractData).catch(api_1.errorHandler);
}
exports.exportAllInvoices = exportAllInvoices;
function updateInvoiceDelayReason(invoiceId, invoiceDelayReasonId) {
    return api_1.default
        .put('/api/invoice/delay-reason/' + invoiceId, {
        invoiceDelayReasonId: invoiceDelayReasonId,
    })
        .then(api_1.extractData)
        .catch(api_1.errorHandler);
}
exports.updateInvoiceDelayReason = updateInvoiceDelayReason;
var api = {
    get: getInvoice,
    create: createInvoice,
    update: updateInvoice,
    delete: deleteInvoice,
    deleteInvoiceItem: deleteInvoiceItem,
    getProjectInvoices: getProjectInvoices,
    getContractInvoices: getContractInvoices,
    updateInvoiceItems: updateInvoiceItems,
    requestApproval: requestInvoiceApproval,
    approve: approveInvoice,
    disapprove: disapproveInvoice,
    setCreatedStatus: setCreatedStatus,
    getSendInvoiceData: getSendInvoiceData,
    sendInvoice: sendInvoice,
    updatePayments: updatePayments,
    deleteInvoicePayment: deleteInvoicePayment,
    createCreditNote: createCreditNote,
    setCreditNoteToApprovedStatus: setCreditNoteToApprovedStatus,
    getLinkedInvoice: getLinkedInvoice,
    getAllInvoices: getAllInvoices,
    deleteFeeFromInvoice: deleteFeeFromInvoice,
    publishInvoiceSent: publishInvoiceSent,
    updateInvoiceDelayReason: updateInvoiceDelayReason,
};
exports.default = api;
