import clientApi from './api'

export function updateContactInformation(clientId, contactInformationData) {
  return clientApi.put(`/api/client/${clientId}/contact-information`, contactInformationData)
}

export function getAtheneumContact() {
  return clientApi.get('/api/client/atheneum-contact')
}

export function getPlatformProjects() {
  return clientApi.get('/api/project/client-projects')
}

export function getExternLink(projectId) {
  return clientApi.post(`/api/project/${projectId}/extern-link`)
}

export function getComplianceAuditEpls(page, limit, orderBy) {
  const baseUrl = '/api/expert-project-link/audit'
  return clientApi.get(`${baseUrl}?page=${page}&limit=${limit}&orderBy=${orderBy}`)
}

export function getSingleAuditEpl(eplId) {
  return clientApi.get(`/api/expert-project-link/audit/${eplId}`)
}

export function updateEpl(eplId, payload) {
  return clientApi.put(`/api/expert-project-link/${eplId}`, payload)
}

export function getCapiProjects(clientName) {
  return clientApi.get(
    `${process.env.REACT_APP_CAPI_SERVICE_URL}/projects?clientName=${encodeURIComponent(clientName)}`,
  )
}

export function deleteCapiProject(projectId) {
  return clientApi.post(`${process.env.REACT_APP_CAPI_SERVICE_URL}/delete-project`, { projectId })
}
